import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { injectIntl, intlShape } from 'react-intl';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Icon from '../../helpers/icon';
import icons from '../../images/selection-icons';
import tt from '../../helpers/translation';

import './Hellheim.scss';

class Hellheim extends React.Component {
  state = {
    openmenu1: false,
    openmenu2: false,
    openmenu3: false,
  };

  handleClick = key =>
    function(e) {
      const obj = {};
      this.state[key] = !this.state[key];
      this.setState(obj);
    }.bind(this);

  render() {
    const {
      state: { openmenu1, openmenu2, openmenu3, openmenu4, openmenu5 },
      props: {
        data: { menus, title, titleType, icon, id },
      },
    } = this;
    return (
      <div className="hellheim-menu">
        {title && (
          <ListItem button className="hellheim-firstlevelmenu-top">
            {titleType === 'h3' ? (
              <>
                <ListItemText
                  className="hellheim-firstlevelmenu-text"
                  inset
                  primary={<h3 id={id}>{`${title}`}</h3>}
                />
                {icon ? (
                  <Icon
                    icon={icon}
                    width="50"
                    height="30"
                    color="black"
                    class="icon"
                    iconSet={icons}
                  />
                ) : null}
              </>
            ) : (
              <ListItemText
                className="hellheim-firstlevelmenu-text"
                inset
                primary={<h4 id={id}>{`${title}`}</h4>}
              />
            )}
          </ListItem>
        )}

        {menus &&
          menus.map((item, index) => {
            return (
              <>
                <ListItem
                  button
                  className="hellheim-firstlevelmenu"
                  onClick={this.handleClick(`openmenu${index}`)}
                >
                  <ListItemText
                    className="hellheim-firstlevelmenu-text"
                    inset
                    primary={`${item.title}`}
                  />
                  {this.state[`openmenu${index}`] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state[`openmenu${index}`]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item &&
                    item.title.includes('WORLDOFPARKS.EU') &&
                    item.title.includes('(2017)') ? (
                      <ListItem
                        button
                        className="hellheim-secondlevelmenu"
                        component="a"
                        href="https://www.worldofparks.eu/wop-award"
                        target="_blank"
                      >
                        <ListItemText
                          className="hellheim-secondlevelmenu-text"
                          inset
                          primary={
                            <div
                              className="text-inner"
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                          }
                          secondary={tt('MÁS INFO', this.props.intl.locale)}
                        />
                      </ListItem>
                    ) : (
                      <ListItem button className="hellheim-secondlevelmenu">
                        <ListItemText
                          className="hellheim-secondlevelmenu-text"
                          inset
                          primary={
                            <div
                              className="text-inner"
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </>
            );
          })}
      </div>
    );
  }
}

Hellheim.propTypes = {
  data: PropTypes.object.isRequired,
};

export default injectIntl(Hellheim);
